import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Paper from '@mui/material/Paper';

export default function Footer() {

  return (
    <Box sx={{ pb: 7 }} >
      <CssBaseline />
      <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0  , backgroundColor: "#1976d2", color: "white" , zIndex: 800}} elevation={3}>
       2022 All Rights reserved &copy; Koch Industries
      </Paper>
    </Box>
  );
}