import './App.css';
import { useAuth } from 'react-oidc-context';
import HeaderFooterLayout from './Components/HeaderFooterLayout';
import Login from './Components/Login';
import { WebStorageStateStore } from 'oidc-client-ts';

function App() {

    const auth = useAuth();
    auth.settings.stateStore = new WebStorageStateStore({ store: window.localStorage });

    switch (auth.activeNavigator) {
        case "signinSilent":
            return <div>Signing you in...</div>;
        case "signoutRedirect":
            return <div>Signing you out...</div>;
    }

    if (auth.isLoading) {
        return <div>Loading...</div>;
    }

    if (auth.error) {
        return <div>Some error occured while authenticating... {auth.error.message}</div>;
    }

    if (auth.isAuthenticated) {
        return (
            <div className="App">
                <HeaderFooterLayout></HeaderFooterLayout>
            </div>
        );
    }

    return Login();
}

export default App;