import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider, AuthProviderProps } from 'react-oidc-context';
import { User, WebStorageStateStore } from 'oidc-client-ts';
import { CookieStorage } from 'cookie-storage';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const cookieStorage =  new CookieStorage();

const oidcConfig: AuthProviderProps = {
  authority: "https://auth.kochid.com",
  client_id: "1565b3b9-d167-459a-bdbf-dd3eb2a259e6",
  redirect_uri: "https://cehs-react-spa-oidc-template.gp.com/auth",
  // stateStore: new WebStorageStateStore({ store: cookieStorage }),
  userStore: new WebStorageStateStore({ store: localStorage }),

  onSigninCallback : ()  => {

        // document.cookie = "username=John Doe; expires=Thu, 18 Dec 2022 12:00:00 UTC;"
        //  window.history.replaceState(
        //      {},
        //      document.title,
        //      window.location.pathname
        //  )
     },

  // client_id: "862c2183-92bc-4789-a46a-1c5c56f036a0",

  
  // redirect_uri: "https://localhost/",
  scope: "entitlements blcName contactInfo employeeInfo emailAddress supervisorNetworkID workCity orgInfo supervisorFirstName preferredName workStreetAddress costCenter openid profile supervisorPersonnelNumber firstName supervisorLastName phone workLocationState domain name costCenterName networkID status lastName displayName workCountry title workLocationZip workLocation personnelidentifier loginAccountInfo workLocationName offline_access blc company department email userPrincipalName supervisorEmailAddress address employmentType supervisorInfo mobile payCompany groups unique-Attribute4 isSupervisor personnelNumber"
};

root.render(
  <React.StrictMode>
      <AuthProvider {...oidcConfig}>
    <App />
    </AuthProvider>
  </React.StrictMode>
);

reportWebVitals();
