import MiniDrawer from "./AppBar";
import Footer from "./Footer";
import Header from "./Header"

const HeaderFooterLayout = () => {

    return (
        <div>
            <div><Header></Header></div>
            <div><MiniDrawer></MiniDrawer></div>
            <div><Footer></Footer></div>
        </div>
        )
}

export default HeaderFooterLayout;