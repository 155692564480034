import { Button, Card, CardActions, CardContent, CardHeader, Grid, TextField } from "@mui/material";
import { useAuth } from "react-oidc-context";
import './Login.css';

const Login = () => {
    const auth = useAuth();

    return (
        <Grid container direction="column" justifyContent="center" alignItems="center" className="align-vertical" sx={{ top: '50%' }}>
            <Grid item xs={6}>
                <Card sx={{ minWidth: 300 }}>
                    <CardHeader component="div">
                        <div>App Login</div>
                    </CardHeader>
                    <CardContent>
                        <Grid container sx={{ padding: '1px', textAlign: 'center' }}>
                            <Grid item xs={4} className="item-right" >Username</Grid>
                            <Grid item xs={8} className="item-left"><TextField id="outlined-basic" label="username" variant="outlined" disabled={true} /></Grid>

                            <Grid item xs={4} className="item-right" >Password</Grid>
                            <Grid item xs={8} className="item-left" ><TextField id="outlined-basic" label="password" variant="outlined" type="password" disabled={true} /></Grid>
                        </Grid>
                    </CardContent>
                    <CardActions>
                        <Grid container >
                            <Grid item xs={4}></Grid>
                            <Grid item xs={8}>
                                <Button variant="contained" size="small" onClick={() => auth.signinRedirect()}>LOGIN</Button>
                            </Grid>
                        </Grid>
                    </CardActions>
                </Card>
            </Grid>
        </Grid>
    );
}

export default Login;