import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import { useAuth } from 'react-oidc-context';
import jwt_decode from "jwt-decode";
 

const pages = ['Home', 'Dashboard', 'Blog'];
const settings = ['Profile', 'Account', 'Dashboard'];

// const colorScheme = "gray"
// const colorSchemeBackground = "white"

const colorScheme = "white"
const colorSchemeBackground = "#1976d2"

const ResponsiveAppBar = () => {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

  const auth = useAuth();

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const userdetails:any = jwt_decode(auth?.user?.id_token!);

  return (
    <AppBar position="fixed" style={{backgroundColor: colorSchemeBackground, color: colorScheme }} >
      <Container maxWidth="xl">
        <Toolbar disableGutters sx={{}}>
          <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />
          <Typography variant="h6" sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}> APP </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton size="large" onClick={handleOpenNavMenu} color="inherit">
              <MenuIcon />
            </IconButton>
            <Menu id="menu-appbar" anchorEl={anchorElNav} keepMounted open={Boolean(anchorElNav)} onClose={handleCloseNavMenu} sx={{
                display: { xs: "block", md: "none" } }} anchorOrigin={{ vertical: "bottom", horizontal: "left" }} 
                transformOrigin={{ vertical: "top", horizontal: "left" }}>
              {pages.map((page) => (
                <MenuItem key={page} onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">{page}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <AdbIcon sx={{ display: { xs: "flex", md: "none" }, mr: 1 }} />
          <Typography variant="h5" sx={{ mr: 2, display: { xs: "flex", md: "none" }, flexGrow: 1 }}>
            APP
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {pages.map((page) => (
              <Button key={page} onClick={handleCloseNavMenu} sx={{ my: 2, color: colorScheme  ,display: "block" }}>
                {page}
              </Button>
            ))}
          </Box>
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt={userdetails?.name?.split(",")[0]} src="/static/images/avatar/2.jpg" sx= {{color: colorSchemeBackground, backgroundColor: colorScheme}} />
              </IconButton>
            </Tooltip>
            <Menu sx={{ mt: "45px" }} id="menu-appbar" keepMounted anchorEl={anchorElUser} open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu} anchorOrigin={{ vertical: "top", horizontal: "right" }}>
               <MenuItem key="info" disabled>
                  <Typography textAlign="center">{userdetails?.name} </Typography>
                </MenuItem>  
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}

              <MenuItem key="logout" onClick={() => auth.removeUser()}>
                  <Typography textAlign="center">Logout</Typography>
                </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default ResponsiveAppBar;
